import React, { useContext, useState } from 'react';
import { Text } from '@bighealth/react-limbix-ui';
import { TextField } from '@material-ui/core';

import { useMutation } from '@apollo/client';

import { useParams } from 'react-router-dom';

import { isValidPhoneNumber } from 'react-phone-number-input';

import { useTranslation } from 'react-i18next';

import Styled from './CaregiverInfo.styles';

import { CTAButton } from '../../components/CTAButton/CTAButton';
import { Titles } from '../../components/Titles/Titles';
import {
  TermsAndConditionsAcknowledgements,
} from '../../components/TermsAndConditionsAcknowledgements/TermsAndConditionsAcknowledgements';
import { DataSharingInfo } from '../../components/DataSharingInfo/DataSharingInfo';
import { OnboardingFlowContext } from '../../OnboardingFlowContext';
import { WhyDoWeNeedThisInfo } from '../../components/OnboardingPopover/popoverContent/WhyDoWeNeedThisInfo';
import validateCaregiverInfoForm from './validateCaregiverInfoForm';

import { OnboardingParams } from '../../types';

import { getPdtUid } from '../../utils/productHelpers';

import { ErrorText } from '../../components/ErrorText/ErrorText';

import { PhoneNumberInput } from '../../components/PhoneNumberInput/PhoneNumberInput';

import { isEmailError } from '../../utils/errorHelpers';

import { validateEmail } from '@/utils/stringValidators';
import { ENROLL_PATIENT } from '@/apollo/mutations';
import { convertDOBToDateString } from '@/utils/stringUtils';
import { identifyUser } from '@/utils/heap';

export const CaregiverInfo: React.FC = () => {
  const {
    updateFormData,
    onboardingFormData,
    next,
    uid,
  } = useContext(OnboardingFlowContext);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const formValid = validateCaregiverInfoForm(onboardingFormData);
  const { product, slug } = useParams<OnboardingParams>();
  const { t } = useTranslation();

  // validate each text field individually. used for error states and helper text...
  const [caregiverFirstNameFieldError, setCaregiverFirstNameFieldError] = useState(false);
  const [caregiverLastNameFieldError, setCaregiverLastNameFieldError] = useState(false);
  const [caregiverPhoneNumberFieldError, setCaregiverPhoneNumberFieldError] = useState(false);
  const [caregiverEmailFieldError, setCaregiverEmailFieldError] = useState(false);
  const [generalErrorMessage, setGeneralErrorMessage] = useState(undefined);

  const [enrollPatient, { loading }] = useMutation(ENROLL_PATIENT);

  const handleEnrollPatient = async () => {
    setGeneralErrorMessage(undefined);
    try {
      const result = await enrollPatient({
        variables: {
          address: onboardingFormData.patientStreetAddress,
          city: onboardingFormData.patientCity,
          dateOfBirth: convertDOBToDateString(onboardingFormData.patientDateOfBirth),
          email: onboardingFormData.patientEmail,
          emergencyContactEmail: onboardingFormData.caregiverEmail,
          emergencyContactName: onboardingFormData.caregiverFirstName,
          emergencyContactPhone: onboardingFormData.caregiverPhoneNumber,
          firstName: onboardingFormData.patientFirstName,
          lastName: onboardingFormData.patientLastName,
          organizationSlug: slug,
          pdtUid: getPdtUid(product),
          phone: onboardingFormData.patientPhoneNumber,
          postalCode: onboardingFormData.patientZipCode,
          state: onboardingFormData.patientState,
          uid,
        },
      });

      if (result?.data?.enrollDirectAccessPatient?.success === true) {
        // creating the user in our system was successful
        identifyUser(onboardingFormData.patientEmail);
        next();
      } else if (isEmailError(result?.errors)) {
        setCaregiverEmailFieldError(true);
      } else {
        // creating the user in our system was UNsuccessful
        console.warn('we were not able to successfully create a profile for this user: ', result);
        setGeneralErrorMessage(t('coverage.errors.generic_error'));
      }
    } catch (e) {
      // There was a server error
      console.log('there was a server error trying to create this user', e);
      setGeneralErrorMessage(t('coverage.errors.generic_error'));
    }
  };

  return (
    <>
      <Titles
        title={t(`coverage.${product}.${onboardingFormData.personEnrolling}.caregiver_title`)}
      />
      <Styled.HoverContainer
        onClick={() => setPopoverOpen(!popoverOpen)}
      >
        <Text
          fontSize="18px"
          fontWeight="400"
          underlined
          lineHeight="24.30px"
        >
          {t(`coverage.${product}.${onboardingFormData.personEnrolling}.caregiver_subtitle`)}
        </Text>
      </Styled.HoverContainer>
      <Text
        color="#363334"
        fontSize="24px"
        fontWeight="700"
      >
        {t(`coverage.${product}.${onboardingFormData.personEnrolling}.caregiver_info_title`)}
      </Text>

      <TextField
        className="input-field"
        variant="outlined"
        margin="normal"
        fullWidth
        required
        error={caregiverFirstNameFieldError}
        helperText={caregiverFirstNameFieldError ? t('coverage.errors.first_name_error') : ''}
        label={t(`coverage.${product}.${onboardingFormData.personEnrolling}.caregiver_f_name_label`)}
        id="caregiverFirstName"
        inputProps={{
          placeholder: t(`coverage.${product}.${onboardingFormData.personEnrolling}.caregiver_f_name_label`),
        }}
        value={onboardingFormData.caregiverFirstName}
        onChange={(e) => {
          setCaregiverFirstNameFieldError(false);
          updateFormData({ caregiverFirstName: e.target.value });
        }}
        onBlur={(e) => {
          setCaregiverFirstNameFieldError(e.target.value === '');
        }}
      />
      <TextField
        className="input-field"
        variant="outlined"
        margin="normal"
        fullWidth
        required
        error={caregiverLastNameFieldError}
        helperText={caregiverLastNameFieldError ? t('coverage.errors.last_name_error') : ''}
        label={t(`coverage.${product}.${onboardingFormData.personEnrolling}.caregiver_l_name_label`)}
        id="caregiverLastName"
        inputProps={{
          placeholder: t(`coverage.${product}.${onboardingFormData.personEnrolling}.caregiver_l_name_label`),
        }}
        value={onboardingFormData.caregiverLastName}
        onChange={(e) => {
          setCaregiverLastNameFieldError(false);
          updateFormData({ caregiverLastName: e.target.value });
        }}
        onBlur={(e) => {
          setCaregiverLastNameFieldError(e.target.value === '');
        }}
      />
      <TextField
        className="input-field"
        variant="outlined"
        margin="normal"
        fullWidth
        required
        error={caregiverEmailFieldError}
        helperText={caregiverEmailFieldError ? t('coverage.errors.email_error') : ''}
        label={t(`coverage.${product}.${onboardingFormData.personEnrolling}.caregiver_email_label`)}
        id="caregiverEmail"
        inputProps={{
          placeholder: t(`coverage.${product}.${onboardingFormData.personEnrolling}.caregiver_email_label`),
        }}
        value={onboardingFormData.caregiverEmail}
        onChange={(e) => {
          setCaregiverEmailFieldError(false);
          updateFormData({ caregiverEmail: e.target.value });
        }}
        onBlur={(e) => {
          setCaregiverEmailFieldError(!validateEmail(e.target.value));
        }}
      />
      <PhoneNumberInput
        placeholder={t(`coverage.${product}.${onboardingFormData.personEnrolling}.caregiver_phone_label`)}
        value={onboardingFormData.caregiverPhoneNumber}
        onChange={(value) => {
          setCaregiverPhoneNumberFieldError(false);
          updateFormData({ caregiverPhoneNumber: value || '' });
        }}
        onBlur={() => {
          setCaregiverPhoneNumberFieldError(!isValidPhoneNumber(onboardingFormData.caregiverPhoneNumber));
        }}
        error={caregiverPhoneNumberFieldError}
        helperText={caregiverPhoneNumberFieldError ? t('coverage.errors.phone_error') : ''}
      />

      <div
        style={{ marginBottom: '32px' }}
      />
      <TermsAndConditionsAcknowledgements
        safetyAcknowledgement={onboardingFormData.safetyAcknowledgement}
        safetyAcknowledgementLabel={t('coverage.safety_acknowledgement_label')}
        policyAndTermsAcknowledgement={onboardingFormData.policyAndTermsAcknowledgement}
        onSelect={(data) => updateFormData(data)}
      />
      {generalErrorMessage && (
        <ErrorText error={generalErrorMessage} />
      )}
      <CTAButton
        text={t('coverage.next')}
        handleClick={handleEnrollPatient}
        isDisabled={formValid === false}
        showLoading={loading}
      />
      <DataSharingInfo />
      <WhyDoWeNeedThisInfo
        personEnrolling={onboardingFormData.personEnrolling}
        popoverOpen={popoverOpen}
        setPopoverOpen={setPopoverOpen}
      />
    </>
  );
};
