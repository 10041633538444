import React from 'react';

import { useTranslation } from 'react-i18next';

import Styled from './CoverageRejection.styles';

import SleepioSighing from '@/assets/SleepioSighing.svg';

type CoverageRejectionProps = {
  isCaregiver: boolean;
};

export const SleepioCoverageRejection: React.FC<CoverageRejectionProps> = () => {
  const { t } = useTranslation();
  return (
    <>
      <Styled.Title>
        {t('coverage.sleepio.rejection.main_title')}
      </Styled.Title>
      <Styled.Text>
        {t('coverage.sleepio.rejection.not_eligible')}
      </Styled.Text>
      <Styled.Text>
        {t('coverage.sleepio.rejection.contact')}
        {' '}
        <Styled.AnchorTag href="mailto:sleepio@bighealth.com">sleepio@bighealth.com</Styled.AnchorTag>
      </Styled.Text>

      <Styled.Gap />
      <Styled.ImageContainer>
        <Styled.LimbotRainyDay src={SleepioSighing} />
      </Styled.ImageContainer>
      <Styled.Gap />
      <Styled.Subtitle>
        {t('coverage.sleepio.rejection.emergency')}
      </Styled.Subtitle>
      <Styled.Text>
        {t('coverage.sleepio.rejection.resource')}
      </Styled.Text>
      <Styled.List>
        <Styled.ListItem>
          <Styled.Text>
            {t('coverage.sleepio.rejection.medical_or_mental')}
            {' '}
            <Styled.BoldText>{t('coverage.sleepio.rejection.dial_911')}</Styled.BoldText>
            {' '}
            {t('coverage.sleepio.rejection.emergency_room')}
          </Styled.Text>
        </Styled.ListItem>
        <Styled.ListItem>
          <Styled.Text>{t('coverage.sleepio.rejection.health_questions')}</Styled.Text>
        </Styled.ListItem>
        <Styled.ListItem>
          <Styled.Text>{t('coverage.sleepio.rejection.crisis_support')}</Styled.Text>
        </Styled.ListItem>
      </Styled.List>
      <Styled.BoxContainer>
        <Styled.BoldText>
          {t('coverage.sleepio.rejection.suicide_lifeline')}
        </Styled.BoldText>
        <Styled.Text>
          <Styled.PhoneTag href="tel:988">
            {t('coverage.sleepio.rejection.call')}
            {' '}
            988
          </Styled.PhoneTag>
        </Styled.Text>
        <Styled.Text>
          {t('coverage.sleepio.rejection.chat_online')}
          {' '}
          <Styled.AnchorTag href="www.988lifeline.org/chat/">www.988lifeline.org/chat/</Styled.AnchorTag>
        </Styled.Text>
      </Styled.BoxContainer>
      <Styled.BoxContainer>
        <Styled.BoldText>
          {t('coverage.sleepio.rejection.crisis_text_label')}
        </Styled.BoldText>
        <Styled.Text>
          {t('coverage.sleepio.rejection.crisis_text')}
          {' '}
          <Styled.PhoneTag href="tel:741741">741741</Styled.PhoneTag>
        </Styled.Text>
      </Styled.BoxContainer>
      <Styled.Gap />
      <Styled.Subtitle>
        {t('coverage.sleepio.rejection.additional_resources')}
      </Styled.Subtitle>
      <Styled.Gap />
      <Styled.BoldText>
        {t('coverage.sleepio.rejection.services_administration')}
      </Styled.BoldText>
      <Styled.Text>
        <Styled.AnchorTag href="https://www.samhsa.gov/find-help/national-helpline">
          https://www.samhsa.gov/find-help/national-helpline
        </Styled.AnchorTag>
      </Styled.Text>
      <Styled.Gap />
      <Styled.BoldText>
        {t('coverage.sleepio.rejection.psychological_association')}
      </Styled.BoldText>
      <Styled.Text>
        <Styled.AnchorTag href="https://locator.apa.org/">
          https://locator.apa.org/
        </Styled.AnchorTag>
      </Styled.Text>
      <Styled.Gap />
      <Styled.BoldText>
        {t('coverage.sleepio.rejection.sleep_academy')}
      </Styled.BoldText>
      <Styled.Text>
        <Styled.AnchorTag href="https://sleepeducation.org/">
          https://sleepeducation.org/
        </Styled.AnchorTag>
      </Styled.Text>
      <Styled.Gap />
      <Styled.Text>
        {t('coverage.sleepio.rejection.disclaimer')}
      </Styled.Text>
    </>
  );
};
