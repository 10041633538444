import React from 'react';
import { Text } from '@bighealth/react-limbix-ui';

import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Styled from './DataSharingInfo.styles';
import { getProductPrivacyPolicy } from '../../utils/productHelpers';
import { OnboardingParams } from '../../types';

export const DataSharingInfo: React.FC = () => {
  const { product } = useParams<OnboardingParams>();
  const { t } = useTranslation();
  return (
    <Text
      color="#363334"
      fontSize="14px"
      fontWeight="400"
      marginTop="24px"
      textAlign="center"
    >
      {`${t('coverage.data_sharing_info')} `}
      <Styled.AnchorTag target="_blank" href={getProductPrivacyPolicy(product)}>
        {t('coverage.privacy_policy')}
      </Styled.AnchorTag>
      .
    </Text>
  );
};
