import React from 'react';

import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Styled from './TermsAndConditionsAcknowledgements.styles';
import { OnboardingParams } from '../../types';
import { getProductPrivacyPolicy, getProductTermsAndConditions } from '../../utils/productHelpers';

interface TermsAndConditionsAcknowledgementsProps {
    safetyAcknowledgement: boolean;
    safetyAcknowledgementLabel: string;
    policyAndTermsAcknowledgement: boolean;
    onSelect: (arg1: {safetyAcknowledgement: boolean} | {policyAndTermsAcknowledgement: boolean}) => void;
}

export const TermsAndConditionsAcknowledgements: React.FC<TermsAndConditionsAcknowledgementsProps> = ({
  safetyAcknowledgement,
  safetyAcknowledgementLabel,
  policyAndTermsAcknowledgement,
  onSelect,
}) => {
  const { product } = useParams<OnboardingParams>();
  const { t } = useTranslation();

  return (
    <>
      <Styled.RadioButtonContainer>
        <Styled.RadioInput
          type="checkbox"
          checked={safetyAcknowledgement}
          onChange={() => {
            onSelect({ safetyAcknowledgement: !safetyAcknowledgement });
          }}
        />
        <Styled.RadioInputLabel>{safetyAcknowledgementLabel}</Styled.RadioInputLabel>
      </Styled.RadioButtonContainer>
      <Styled.RadioButtonContainer>
        <Styled.RadioInput
          type="checkbox"
          checked={policyAndTermsAcknowledgement}
          onChange={() => {
            onSelect({ policyAndTermsAcknowledgement: !policyAndTermsAcknowledgement });
          }}
        />
        <Styled.RadioInputLabel>
          {`${t('coverage.i_agree_to_the')} `}
          <Styled.AnchorTag
            target="_blank"
            rel="noopener noreferrer"
            href={getProductTermsAndConditions(product)}
          >
            {t('coverage.terms')}
          </Styled.AnchorTag>
          {`, ${t('coverage.and')} `}
          <Styled.AnchorTag
            target="_blank"
            rel="noopener noreferrer"
            href={getProductPrivacyPolicy(product)}
          >
            {`${t('coverage.privacy_policy')}.`}
          </Styled.AnchorTag>
        </Styled.RadioInputLabel>
      </Styled.RadioButtonContainer>
    </>
  );
};
